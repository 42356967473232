import { signIn } from "../api";
import * as regex from "../lib/pattern";
import { setSession } from "../lib/session";
import { client } from "../lib";

const form = document.querySelector("#signin__form") as HTMLFormElement;
const emailInput = document.querySelector("#signin__email") as HTMLInputElement;
const passwordInput = document.querySelector(
  "#signin__password"
) as HTMLInputElement;
const failMessage = document.querySelector(".message_fail") as HTMLDivElement;
const submitButton = document.querySelector(
  "#signin__submit"
) as HTMLInputElement;

const savedIdCheckbox = document.querySelector("#saved-id") as HTMLInputElement;

const version = process.env.NODE_ENV_VERSION || "develop";

interface State {
  savedId: boolean;
}

//
const state: State = {
  savedId: false,
};

// Window Load
document.addEventListener("DOMContentLoaded", function () {
  if (localStorage.getItem("clickmate_saved_id")) {
    emailInput.value = localStorage.getItem("clickmate_saved_id") || "";
    savedIdCheckbox.checked = true;
    state.savedId = true;
  }

  savedIdCheckbox.addEventListener("change", (ev) => {
    const checked = (ev.target as HTMLInputElement).checked;
    state.savedId = checked;
  });
});

// Submit (Sign In)
form.addEventListener("submit", async function (ev) {
  ev.preventDefault();

  const email = emailInput.value;
  const password = passwordInput.value;
  const { browser, os } = client();

  // 유효성 확인
  const valid = validation(email, password);

  if (!valid) return;

  submitButton.disabled = true;

  try {
    const { data } = await signIn({
      email,
      password,
      serviceType: "SWEB",
      client: browser,
      os,
      version,
    });

    console.log(data);

    const payload = data.data?.payload;

    if (!payload || data.status !== 200) {
      failMessage.innerText = data.message;
      return;
    }

    if (state.savedId) {
      localStorage.setItem("clickmate_saved_id", email);
    } else {
      localStorage.removeItem("clickmate_saved_id");
    }

    setSession(payload);

    failMessage.innerText = "";
    window.location.replace("./live-list");
  } catch (err) {
    //
  } finally {
    submitButton.disabled = false;
  }
});

/**
 * 유효성 체크
 * @param email
 * @param password
 * @returns
 */
function validation(email: string, password: string) {
  if (!email) {
    failMessage.innerText = "이메일을 작성해주세요.";
    return;
  }

  if (email.length > 35) {
    failMessage.innerText = "이메일은 35자 이하로 작성해주세요.";
    return;
  }

  if (!regex.EMAIL.test(email)) {
    failMessage.innerText = "이메일을 형식을 확인해주세요.";
    return;
  }

  if (!password) {
    failMessage.innerText = "비밀번호를 작성해주세요.";
    return;
  }

  if (password.length < 8 || password.length > 18) {
    failMessage.innerText = "비밀번호는 8이상 18자이하로 작성해주세요.";
    return;
  }

  return true;
}
