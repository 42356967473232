// browser, os 정보
export const client = () => {
  return {
    os: os(),
    browser: browser(),
  };
};

// os 정보
export const os = () => {
  const agent = window.navigator.userAgent;
  if (/(Mac)/gi.test(agent)) return "Mac";
  if (/(Windows)/gi.test(agent)) return "Windows";
  return "None";
};

// browser 정보
export const browser = () => {
  const agent = window.navigator.userAgent;
  if (/(Whale)/gi.test(agent)) return "Whale";
  if (/(Safari)/gi.test(agent)) return "Safari";
  if (/(Chrome)/gi.test(agent)) return "Chrome";
  return "None";
};

// 딜레이 함수
export const sleep = (duration: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};

// window location hash uuid
export const getUUID = (includeHash = false) => {
  const hash = window.location.hash.substring(1);
  let uuid = hash.split("/")[0].trim();

  if (uuid.split("?").length > 1) {
    uuid = uuid.split("?")[0];
  }

  if (uuid && includeHash) return "#" + uuid;

  return uuid;
};

export const getQuery = (key: string) => {
  let search = window.location.search;

  // hash 가 존재하는 경우
  if (window.location.hash) {
    const parts = window.location.hash.split("?");
    search = parts[parts.length - 1];
  }

  const searchParams = new URLSearchParams(search);
  return searchParams.get(key);
};

export const comma = (num?: number | string | null) =>
  num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";

export const rmComma = (num?: string | number | null, onlyNumber = false) => {
  if (!num || num === 0 || String(num).trim() === "") return 0;

  // , 제거
  num = String(num).trim().replace(",", "");
  // 숫자만 뽑기
  if (onlyNumber && !/^[0-9]+$/.test(num))
    num = num.replace(/^[0]|[^0-9]/g, "");

  return Number(num);
};

export const splitComma = (value?: string | number | null) => {
  const str = String(value);
  if (!value || str.trim() === "") return [];
  return str
    .split(",")
    .map((v) => v.trim())
    .filter((v) => v !== "");
};

export const isOnlyNumberWithZero = (value?: string | number | null) => {
  if (value === null || value === undefined) return false;
  if (value === "") return true;
  return /^[0-9]*$/.test(value.toString());
};

export const isOnlyNumber = (value?: string | number | null) => {
  if (value === null || value === undefined) return false;
  if (value === "") return true;
  return /^[1-9][0-9]*$/.test(value.toString());
};

export const numberInputListener = (input: HTMLInputElement) => {
  if (!isOnlyNumber(input.value)) {
    const newValue = input.value.replace(/^[0]|[^0-9]/g, "");
    input.value = newValue;
  }

  return input.value;
};

export const numberIncludeZeroInputListener = (input: HTMLInputElement) => {
  if (input.value[0] === "0") input.value = "0";

  if (!isOnlyNumberWithZero(input.value)) {
    const newValue = input.value.replace(/^[0-9]/g, "");
    input.value = newValue;
  }

  return input.value;
};
