import { SignInPayload } from "../api/type";

export const storageKey = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",
};

export const getSession = () => {
  const session = localStorage.getItem(storageKey.accessToken);
  if (!session) return null;
  return session;
};

export const setSession = (session: SignInPayload) => {
  if (!session.refreshToken) return;
  localStorage.setItem(storageKey.accessToken, session.refreshToken);
};

export const clearSession = () => {
  localStorage.removeItem(storageKey.accessToken);
};
