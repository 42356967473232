import axios, { AxiosError } from "axios";
import { clearSession, getSession, storageKey } from "../lib/session";

const NODE_ENV = process.env.NODE_ENV;
const baseURL =
  NODE_ENV === "development"
    ? "https://seller-api.clickmate.co.kr"
    : "https://seller-api.clickmate.link";

console.log("env:", NODE_ENV);

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  function (request) {
    return request;
  },
  function (err) {
    throw err;
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err: AxiosError) {
    if (err.response && err.response.status === 401) {
      const data = err.response.data as any;

      if (data) {
        let message = data.message;
        if (data.code === "000001" || data.code === "000006") {
          alert("세션이 만료되어 로그인 페이지로 이동합니다.");
          localStorage.removeItem(storageKey.accessToken);

          if (window) window.location.href = "/";
          return;
        }

        if (data.code === "100003") message = "로그인에 실패했습니다.";

        alert(message);
      }
    }

    if (err.response && err.response.status === 422) {
      return err;
    }

    throw err;
  }
);

export const setSellerHeader = () => {
  const session = getSession();

  if (!session) {
    clearSession();
    return;
  }

  instance.defaults.headers.common["Authorization"] = `Bearer ${session}`;
};

export default instance;
